<template>
  <div class="preview_item_social_channels">
    <ul>
      <li v-for="channel in channels_option" v-if="getSocialChannelPreviewLink(channel, channels)">
        <a target="_blank" :href="getSocialChannelPreviewLink(channel, channels)">
          <i class="fab" :style="{color: `${social_color} !important`}"  :class="['fa-' + channel, channel]"></i>
        </a>
      </li>
    </ul>

  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import bioLinkMixin from '@/mixins/bio-link-mixin'

export default ({
  methods: {},
  mixins: [bioLinkMixin],
  props: {channels: {}, channels_option: {}, social_color: ''},
  computed: {
    ...mapGetters([])
  }
})
</script>
