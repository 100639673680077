<template>
    <div class="mb-2">
      <rss-card-one v-if="['card-1', 'card-2'].includes(section.style)" :isPreview="isPreview" :getBioLinkAdds="getBioLinkAdds" :card="section"></rss-card-one>
      <rss-thumb-one v-if="['thumb-1', 'thumb-2'].includes(section.style)" :isPreview="isPreview" :getBioLinkAdds="getBioLinkAdds" :card="section"></rss-thumb-one>
      <rss-strip v-if="section.style==='strip'" :isPreview="isPreview" :getBioLinkAdds="getBioLinkAdds" :card="section"></rss-strip>
    </div>
</template>
<script>

export default ({
  props: {
    isPreview: false,
    getBioLinkAdds: {},
    section: {}
  },
  components: {
    'rss-card-one': () => import('../bio-common-components/style/RssCardOne'),
    'rss-thumb-one': () => import('../bio-common-components/style/RssThumbOne'),
    'rss-strip': () => import('../bio-common-components/style/RssStrip')
  }
})
</script>
