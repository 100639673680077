<template>
  <div>
    <!--d-flex align-items-center-->
    <div class="preview_item_link " v-for="link in links"
         v-if="link.url && link.show_links"
         :style="[getBioLinkAdds.theme == 'customized' && isPreview ?
                { 'background-color': getBioLinkAdds.custom_theme.button_color + ' !important'} : {}]">
      <a class="d-block" :href="link.url" target="_blank"
         :style="[getBioLinkAdds.theme == 'customized' && isPreview ?
                { 'color': getBioLinkAdds.custom_theme.button_text_color + ' !important'} : {}]">
        {{ link.title }}
      </a>
      <!--<span class="preview_item_link_icon"><i class="far fa-link"></i></span>-->
    </div>
  </div>

</template>
<script>


export default ({
  methods: {},
  props: {
    links: {},
    isPreview: false,
    getBioLinkAdds: {}
  }
})
</script>
