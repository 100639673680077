<template>
  <div class="bio-preview-block" :class="getBioLinkAdd.theme">
    <div class="preview-inner">
      <div class="preview-mockup">
        <img class="mockup-image" src="/assets/img/phone_mockup.svg" alt="">
        <div class="preview-mockup-inner" :style="computeBioStyle(getBioLinkAdd)">
          <div class="preview-mockup-inner-content">
            <div class=" profile__box">
              <div class="avatar">
                <!--<div class="brand_img" :style="{ 'background': 'url(' + brand.avatar + ')' }"></div>-->
                <div class="brand_img" v-if="getBioLinkAdd.image"
                     :style="getBioBackgroundStyleImage(getBioLinkAdd.image)"></div>
                <div class="brand_img" v-else
                     style="background: url('https://storage.googleapis.com/replug/profile-default.png');"></div>
              </div>
              <div class="content" v-if="getBioLinkAdd.page_title">
                <div class="url">
                  <p :style="{'color': getBioLinkAdd.title_color}">{{ getBioLinkAdd.page_title }}</p>
                </div>
                <div class="url">
                  <p style="font-weight: 400 !important;"
                     :style="{'color': getBioLinkAdd.sub_title_color}">{{ getBioLinkAdd.page_sub_title }}</p>
                </div>
              </div>

            </div>
            <div class="preview_body">
              <template v-if="!allowed">
                <template v-for="section in getBioLinkAdd.content_sections">
                  <div class="title-name d-block text-center" v-if="section.title && section.show_title"
                       :key="section._id">
                    <span :style="{'color': getBioLinkAdd.block_title_color }">{{ section.title }}</span>
                  </div>
                  <template v-if="section.type === types.links">
                    <bio-short-link-snippet
                      isPreview="true"
                      :links="section.links" :getBioLinkAdds="getBioLinkAdd"></bio-short-link-snippet>
                  </template>
                  <template v-if="section.type === types.social">
                    <bio-short-social-snippet
                      :channels_option="section.channels_option"
                      :channels="section.channels"
                      :social_color="section.social_color"
                    ></bio-short-social-snippet>
                  </template>
                  <template v-if="section.type === types.rss">
                    <bio-short-posts-snippet isPreview="true" :getBioLinkAdds="getBioLinkAdd"
                                             :section="section"></bio-short-posts-snippet>
                  </template>
                  <template v-if="section.type === types.audio">
                    <bio-short-audio-snippet
                      :isPreview="true"
                      :section="section" :getBioLinkAdds="getBioLinkAdd"></bio-short-audio-snippet>
                  </template>

                  <template v-if="section.type === types.video">
                    <bio-short-video-snippet
                      :isPreview="true"
                      :getBioLinkAdds="getBioLinkAdd"
                      :section="section"></bio-short-video-snippet>
                  </template>
                  <template v-if="section.type === types.card">
                    <bio-short-card-snippet
                      :isPreview="true"
                      :section="section" :getBioLinkAdds="getBioLinkAdd"></bio-short-card-snippet>
                  </template>
                  <template v-if="section.type === types.text">
                    <bio-short-text-snippet :section="section" :getBioLinkAdds="getBioLinkAdd"></bio-short-text-snippet>
                  </template>
                </template>
              </template>
              <template v-else>
                <div class="title-name d-block text-center"
                     v-if="getBioLinkRssSectionAdd.title && getBioLinkRssSectionAdd.show_title">
                  <span
                    :style="{'color': getBioLinkAdd.block_title_color }">{{ getBioLinkRssSectionAdd.title }}</span>
                </div>

                <div class="title-name d-block text-center"
                     v-if="getBioLinkContentSectionAdd.title && getBioLinkContentSectionAdd.show_title">
                  <span
                    :style="{'color': getBioLinkAdd.block_title_color }">{{ getBioLinkContentSectionAdd.title }}</span>
                </div>

                <template v-if="getBioLinkContentSectionAdd.type === types.links">
                  <bio-short-link-snippet
                    isPreview="true"
                    :links="getBioLinkContentSectionAdd.links" :getBioLinkAdds="getBioLinkAdd"></bio-short-link-snippet>
                </template>
                <template v-if="getBioLinkContentSectionAdd.type === types.social">
                  <bio-short-social-snippet
                    :channels_option="getBioLinkContentSectionAdd.channels_option"
                    :channels="getBioLinkContentSectionAdd.channels"
                    :social_color="getBioLinkContentSectionAdd.social_color"
                  ></bio-short-social-snippet>
                </template>
                <template v-if="getBioLinkRssSectionAdd.type === types.rss">
                  <bio-short-posts-snippet
                    isPreview="true"
                    :getBioLinkAdds="getBioLinkAdd"
                    :section="getBioLinkRssSectionAdd"></bio-short-posts-snippet>
                </template>
                <template v-if="getBioLinkAudioSectionAdd.type === types.audio">
                  <bio-short-audio-snippet
                    :isPreview="true"
                    :getBioLinkAdds="getBioLinkAdd"
                    :section="getBioLinkAudioSectionAdd"></bio-short-audio-snippet>
                </template>
                <template v-if="getBioLinkVideoSectionAdd.type === types.video">
                  <bio-short-video-snippet
                    :isPreview="true"
                    :getBioLinkAdds="getBioLinkAdd"
                    :section="getBioLinkVideoSectionAdd"></bio-short-video-snippet>
                </template>
                <template v-if="getBioLinkCardSectionAdd.type === types.card">
                  <bio-short-card-snippet
                    :isPreview="true"
                    :section="getBioLinkCardSectionAdd" :getBioLinkAdds="getBioLinkAdd"></bio-short-card-snippet>
                </template>
                <template v-if="getBioLinkTextSectionAdd.type === types.text">
                  <bio-short-text-snippet :getBioLinkAdds="getBioLinkAdd"
                                          :section="getBioLinkTextSectionAdd"></bio-short-text-snippet>
                </template>
              </template>
            </div>
          </div>
        </div>
        <div class="bio_bottom_logo" v-if="computePowerBy() && !isWhiteLabelSetup()" :class="getBioLinkAdd.theme" :style="[getBioLinkAdd.theme === 'customized' ? { 'background-color': getBioLinkAdd.custom_theme.background_color + ' !important'} : {}]">
          <div class="footer text-center">
            <a :href="`${getSiteDetails.white_label_domain}?utm_campaign=biolinks-footer`" target="_blank">
              <span :style="{'color': getBioLinkAdd.title_color}">Powered by </span>
              <img :src="getSiteDetails.favicon" style="width: 30px;" alt="">
              <span class="ml-1" :style="{'color': getBioLinkAdd.title_color}">{{ getSiteDetails.agency_name }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import bioLinkMixin from '@/mixins/bio-link-mixin'
import BioShortSocialSnippet from './short/BioShortSocialSnippet'
import BioShortLinkSnippet from './short/BioShortLinkSnippet'
import BioShortPostsSnippet from './short/BioShortPostsSnippet'
import {bio_content_type} from "../../../../../common/attributes";

export default ({
  data () {
    return {
      types: bio_content_type
    }
  },
  mixins: [bioLinkMixin],
  methods: {},
  components: {
    BioShortSocialSnippet,
    BioShortLinkSnippet,
    BioShortPostsSnippet,
    'bio-short-audio-snippet': () => import('./short/BioShortAudioSnippet.vue'),
    'bio-short-video-snippet': () => import('./short/BioShortVideoSnippet.vue'),
    'bio-short-card-snippet': () => import('./short/BioShortCardSnippet.vue'),
    'bio-short-text-snippet': () => import('./short/BioShortTextSnippet.vue')
  },
  computed: {
    ...mapGetters(['getBioLinkRssSectionAdd', 'getBioLinkAdd', 'getBioLinkContentSectionAdd', 'getBioLinkAudioSectionAdd', 'getBioLinkVideoSectionAdd', 'getBioLinkCardSectionAdd', 'getBioLinkTextSectionAdd']),
    allowed () {
      return ['content_social_block', 'content_links_block', 'content_rss_block', 'content_audio_block', 'content_video_block', 'content_card_block', 'content_text_block'].includes(this.$route.name)
    }
  },
  mounted () {
  }
})

</script>
<style lang="less">

</style>
